import { ApolloError, FetchResult } from '@apollo/client';
import { setUser as setSentryUser } from '@sentry/nextjs';
import { isBrowser } from '@utils/isBrowser';
import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { usePrevious } from 'react-use';
import Cookies from 'universal-cookie';

import { csrClient } from '../apollo/api/avantArteClient';
import {
  UTM_CAMPAIGN_KEY,
  UTM_MEDIUM_KEY,
  UTM_ORIGIN,
  UTM_SOURCE_KEY,
} from '../constants';
import useSessionStorage from '../hooks/useSessionStorage';
// eslint-disable-next-line import/no-cycle
import { AuthState } from '../types/Auth';
import {
  GeneratedAccount,
  GeneratedCreateSubscriptionsInput,
  GeneratedLoginMutation,
  GeneratedLogoutMutation,
  GeneratedSignupAndSubscribeMutation,
  GeneratedUseLoginCodeMutation,
  useGetAccountLazyQuery,
  useLoginMutation,
  useLogoutMutation,
  useSignupAndSubscribeMutation,
  useUseLoginCodeMutation,
} from '../types/generated';
import { identifyUser } from '../utils/analytics';
import { captureError } from '../utils/capture';

export interface AuthContextType {
  authState: AuthState | undefined;
  isLoggedIn: boolean | undefined;
  user?: GeneratedAccount;
  error?: ApolloError;
  login(
    email: string,
    captchaToken?: string,
  ): Promise<FetchResult<GeneratedLoginMutation>>;
  loginGoogle(
    googleCredential: string,
  ): Promise<FetchResult<GeneratedUseLoginCodeMutation>>;
  signupAndSubscribe({
    captchaToken,
    email,
    silenceLoginCodeEmail,
    subscriptions,
  }: {
    email: string;
    captchaToken: string | undefined;
    silenceLoginCodeEmail?: boolean;
    subscriptions?: GeneratedCreateSubscriptionsInput[];
  }): Promise<FetchResult<GeneratedSignupAndSubscribeMutation>>;
  validateCode(
    code: string,
    email: string,
  ): Promise<FetchResult<GeneratedUseLoginCodeMutation>>;
  logout(redirect?: boolean): Promise<FetchResult<GeneratedLogoutMutation>>;
  /** The email the user is attempting to sign up / sign in with. This is only used during the authentication flow. */
  emailForCode?: string;
}

export const AuthContext = createContext<AuthContextType>({
  authState: undefined,
  isLoggedIn: undefined,
  login: async () => Promise.resolve({}),
  loginGoogle: async () => Promise.resolve({}),
  logout: async () => Promise.resolve({}),
  signupAndSubscribe: async () => Promise.resolve({}),
  validateCode: async () => Promise.resolve({}),
});

const loggedInCookie = (
  process.env.NEXT_PUBLIC_API_ENDPOINT as string
)?.includes('staging')
  ? 'aa-logged-in-dev'
  : 'aa-logged-in';

export const AuthProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const uc = useMemo(() => new Cookies(), []);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const isLoggedIn = Boolean(uc.getAll()[loggedInCookie]);

  const [user, setUser] = useState<GeneratedAccount>();
  const [emailForCode, setEmailForCode] = useState<string>();
  const [authState, setAuthState] = useState<AuthState>(
    isLoggedIn ? AuthState.LOGGED_IN : AuthState.LOGGED_OUT,
  );

  const { getSessionStorage } = useSessionStorage();

  const [signUpAndSubscribe] = useSignupAndSubscribeMutation();

  const [login] = useLoginMutation();
  const [logout] = useLogoutMutation();
  const [getAccount, { data: accountData }] = useGetAccountLazyQuery();
  const [validateCode] = useUseLoginCodeMutation();
  const prevAccountId = usePrevious(accountData?.account?.id);

  const originData = useMemo(
    () => ({
      origin: UTM_ORIGIN,
      originUTMCampaign: getSessionStorage(UTM_CAMPAIGN_KEY),
      originUTMMedium: getSessionStorage(UTM_MEDIUM_KEY),
      originUTMSource: getSessionStorage(UTM_SOURCE_KEY),
    }),
    [getSessionStorage],
  );

  useEffect(() => {
    if (isLoggedIn && !user) {
      getAccount();
      setAuthState(AuthState.LOADING);
    } else if (!isLoggedIn && user) {
      setUser(undefined);
      setAuthState(AuthState.LOGGED_OUT);
    }
  }, [getAccount, isLoggedIn, user]);

  useEffect(() => {
    if (accountData?.account?.id === prevAccountId) return;
    const authUser = accountData?.account;
    if (authUser) {
      setUser({ ...authUser, isCurrentUser: true });
      setSentryUser({
        id: authUser.id,
        username: authUser.username ?? undefined,
      });

      identifyUser({ ...authUser, email: authUser.email as string });
      setAuthState(AuthState.LOGGED_IN);
    }
  }, [accountData?.account]);

  const handleSignUp = useCallback(
    async ({
      captchaToken,
      email,
      silenceLoginCodeEmail = false,
      subscriptions = [],
    }: {
      captchaToken: string | undefined;
      email: string;
      silenceLoginCodeEmail?: boolean;
      subscriptions: GeneratedCreateSubscriptionsInput[];
    }): Promise<
      FetchResult<
        GeneratedSignupAndSubscribeMutation,
        Record<string, string>,
        Record<string, string>
      >
    > => {
      setAuthState(AuthState.LOADING);
      setEmailForCode(email);

      try {
        const response = await signUpAndSubscribe({
          variables: {
            email,
            captcha: captchaToken,
            subscriptions,
            silenceLoginCodeEmail,
            ...originData,
          },
        });
        if (response.errors?.length && response.errors?.length > 0) {
          setAuthState(AuthState.ERROR);
        } else if (response.data?.signupAndSubscribe) {
          setAuthState(AuthState.CODE_PENDING);
        }
        return response;
      } catch (e) {
        captureError(e as ApolloError);
        setAuthState(AuthState.ERROR);
        throw e;
      }
    },
    [originData, signUpAndSubscribe],
  );

  const handleLogin = useCallback(
    async (email: string, captcha?: string) => {
      setAuthState(AuthState.LOADING);
      setEmailForCode(email);

      try {
        const response = await login({
          variables: { email, ...originData, captcha },
        });

        if (response.errors?.length && response.errors?.length > 0) {
          setAuthState(AuthState.ERROR);
        } else if (response.data?.login) {
          setAuthState(AuthState.CODE_PENDING);
        }

        return response;
      } catch (e) {
        captureError(e as ApolloError);
        setAuthState(AuthState.ERROR);
        throw e;
      }
    },
    [login, originData],
  );

  const handleLoginGoogle = useCallback(
    async (googleCredential: string) => {
      setAuthState(AuthState.LOADING);
      const response = await validateCode({
        variables: {
          googleCredential,
          ...originData,
        },
      });

      if (response.errors?.length && response.errors?.length > 0) {
        setAuthState(AuthState.ERROR);
      }

      if (response?.data?.useLoginCode.account) {
        getAccount();
        setAuthState(AuthState.LOGGED_IN);
      }

      return response;
    },
    [validateCode, getAccount, originData],
  );

  const handleValidateCode = useCallback(
    async (code: string, email: string) => {
      setAuthState(AuthState.LOADING);
      try {
        const response = await validateCode({
          variables: { code, email },
        });

        if (response.errors?.length && response.errors?.length > 0) {
          setAuthState(AuthState.ERROR);
        }

        if (response?.data?.useLoginCode.account) {
          getAccount();
          setAuthState(AuthState.LOGGED_IN);
        }

        return response;
      } catch (e) {
        captureError(e as ApolloError);
        setAuthState(AuthState.ERROR);
        throw e;
      }
    },
    [validateCode, getAccount],
  );

  const handleLogout = useCallback(
    async (redirect = true) => {
      const response = await logout();

      if (response?.data?.logout) {
        setSentryUser(null);
        setUser(undefined);
        setAuthState(AuthState.LOGGED_OUT);
        csrClient.cache.reset();
        uc.remove(loggedInCookie);

        const isProduction =
          isBrowser() && window.location.host === 'avantarte.com';

        if (!isProduction) {
          // Do not redirect to Shopify logout page in dev or staging
          return response;
        }

        if (redirect) {
          window.location.replace(process.env.NEXT_PUBLIC_SHOPIFY_LOGOUT_URL!);
        }
      }

      return response;
    },
    [logout, uc],
  );

  const context = useMemo(
    () => ({
      authState,
      emailForCode,
      isLoggedIn: authState === AuthState.LOGGED_IN,
      login: handleLogin,
      loginGoogle: handleLoginGoogle,
      logout: handleLogout,
      signupAndSubscribe: handleSignUp,
      user,
      validateCode: handleValidateCode,
    }),
    [
      authState,
      emailForCode,
      handleLogin,
      handleLoginGoogle,
      handleLogout,
      handleSignUp,
      handleValidateCode,
      user,
    ],
  );

  return (
    <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => React.useContext(AuthContext);
