import { ApolloError, isApolloError } from '@apollo/client';
import { AccountErrorResponse } from '../types/Account';
import { GraphQLError } from 'graphql';

export const tryEach = <T>(...attempts: (() => T)[]): T | null => {
  while (attempts.length > 0) {
    const attempt = attempts.shift()!;
    try {
      return attempt();
    } catch (e) {}
  }

  return null;
};

export const isApiValidationError = (
  err: any,
  field: string,
  description: string,
) => {
  if (!isApolloError(err)) return false;

  const error = err.graphQLErrors[0];
  if (error.extensions?.code !== 'INVALID') return false;

  const errorField = error.extensions.fields.find(
    (f: any) => f.field === field,
  );

  return errorField?.description === description;
};

export const isRateLimitError = (err: any) => {
  const gqlErr = err?.graphQLErrors?.[0] as GraphQLError | undefined;

  return gqlErr?.extensions?.code === 'RATE_LIMITED';
};

export const isLoginCodeExpiredError = (err: any) => {
  const gqlErr = err?.graphQLErrors?.[0] as GraphQLError | undefined;

  // Checking extensions.code === 'CODE_EXPIRED' is not enough because this is also returned for unauthorized requests.
  // FIXME: we should clean this up e2e and only return CODE_EXPIRED when the code is actually expired.
  // https://linear.app/avantarte/issue/COL-2050/clean-up-approach-for-expired-code
  return gqlErr?.message === 'login code expired';
};

export const isLoginCodeInvalidError = (err: any) => {
  const gqlErr = err?.graphQLErrors?.[0] as GraphQLError | undefined;

  return gqlErr?.extensions?.code === 'CODE_EXPIRED';
};

type InvalidInputErr = {
  code: 'INVALID';
  fields: Array<{ field: string; description: string }>;
};

function isInvalidInputErr(
  ext?: Record<string, unknown>,
): ext is InvalidInputErr {
  return (
    ext !== undefined &&
    (ext as InvalidInputErr).code === 'INVALID' &&
    (ext as InvalidInputErr).fields.length !== 0 &&
    (ext as InvalidInputErr).fields[0].field !== undefined &&
    (ext as InvalidInputErr).fields[0].description !== undefined
  );
}

export function stringifyApolloError(error?: ApolloError) {
  const extensions = error?.graphQLErrors?.[0]?.extensions;
  if (!isInvalidInputErr(extensions)) return error?.message || '';

  const firstErr = extensions.fields[0];
  return `${firstErr.field} ${firstErr.description}`;
}

export function isAccountError(obj: unknown): obj is AccountErrorResponse {
  return (obj as AccountErrorResponse).error !== undefined;
}

export const extractApolloError = (
  errors: readonly GraphQLError[] | undefined,
) => {
  if (errors === undefined || errors.length === 0) return null;

  return new ApolloError({ graphQLErrors: errors });
};
