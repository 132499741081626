import { captureError } from '../capture';
import { isBrowser as isBrowserUtil } from '@utils/isBrowser';
import { Event, EventOptions } from './event';
import { Provider } from './providers/types';

export interface AnalyticsClient {
  emit: (
    name: string,
    action: string,
    object?: string,
    flow?: string,
    properties?: Record<string, unknown>,
    options?: EventOptions,
  ) => Promise<void>;
  providers: Provider[];
}

export function createAnalyticsClient(providers: Provider[]): AnalyticsClient {
  const isBrowser = isBrowserUtil();
  async function emit(
    name: string,
    action: string,
    object?: string,
    flow?: string,
    properties?: Record<string, unknown>,
    options?: EventOptions,
  ) {
    // Create event
    const event: Event = {
      version: '2022-10-13',
      flow,
      object,
      action,
      name: name || void 0,
      properties: { ...properties },
      ecommerce: {
        ...(properties?.ecommerce as Record<string, unknown>),
      },
      context: {
        // TODO(afr): Pull in more global context
        href: window.location.href,
        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight,
        user_agent: window.navigator.userAgent,
      },
    };

    // Handle options
    const opts: EventOptions = options ?? {};

    if (opts.omitContext) event.context = void 0;
    if (opts.omitObject) event.object = void 0;

    // Check if we should output console logs of events
    const shouldPrintLogs =
      isBrowser && window.location.hostname !== 'avantarte.com';

    // Don't send events for storyblok pages
    if (isBrowser && window.location.href.indexOf('storyblok') > 0) return;

    if (shouldPrintLogs) {
      console.group('Analytics event:');
      console.table(event);
      console.groupEnd();
    }

    // Fire events to all providers
    await Promise.all(
      providers.map(async (p) => {
        try {
          await p.emit(event);
        } catch (error) {
          captureError(error);
          console.error(`analytic provider ${p.id} failed`, event);
        }
      }),
    );
  }

  const client: AnalyticsClient = {
    emit,
    providers,
  };

  if (isBrowser) {
    window.AAAnalyticsClient = client;
  }

  return client;
}
