import { differenceInHours } from 'date-fns';
import {
  PRODUCT_TAG_DISABLE_CRYPTO,
  PRODUCT_TAG_PINNED_ON_HOMEPAGE,
  PRODUCT_TAG_FREE_SHIPPING,
  PRODUCT_TAG_NO_RESALE_AGREEMENT,
  PRODUCT_TAG_DISABLE_PAYPAL,
  PRODUCT_TAG_SAME_PRICE,
  PRODUCT_TAG_HANDFINISHED,
  PRODUCT_TAG_NO_TAX_CHARGED,
  PRODUCT_TAG_COA_2_YEAR_DISPATCH,
  PRODUCT_TAG_OPEN_EDITION,
  PRODUCT_TAG_CHRISTMAS_DELIVERY_2022,
  PRODUCT_TAG_ALLOW_MULTIPLE_ITEMS,
  PRODUCT_TAG_ONE_TAP_CHECKOUT,
  PRODUCT_TAG_ENQUIRE_ONLY_NO_PRICE,
  PRODUCT_TAG_FEW_LEFT,
  PRODUCT_TAG_DISABLE_EXPRESS,
  PRODUCT_TAG_NO_COA,
} from '../constants/product';
import { combineSorters } from '../helpers/sorting';
import type { CurrencyCode } from '../types/UserPreferences';
import type {
  AvailabilityFilter,
  FinishFilter,
  MediumFilter,
  ReleaseFilter,
  ShippingFilter,
} from '../types/Filters';
import type {
  ProductPurchaseDetails,
  ProductPurchasingController,
  ShopifyFrameProduct,
  ShopifyProductToCheckout,
} from '../types/Purchasing';
import type {
  AugmentedShopifyFrame,
  ProductBenefits,
  ProductTag,
} from '../types/Products';
import { CountryCode } from '../types/Locations';
import { KLARNA_COUNTRIES, KLARNA_MAX_IN_EUROS } from '../config/Klarna';
import { shopifyAdminIdToGraphqlId } from './shopifyIds';
import { framedByDefault, getFrameBaseVariant } from './frames';
import {
  ProductStoryblok,
  ProductV2Storyblok,
} from '../types/generated-storyblok';
import { DrawByProductId } from '../features/draws/types';

export const DEFAULT_CURRENCY_CODE = 'EUR';

const countryCurrencyCodes = {
  'GBP': 'GB',
  'USD': 'US',
  'EUR': 'NL',
};

export const getCountryByCurrency = (currency: CurrencyCode) => {
  return countryCurrencyCodes[currency];
};

export const selectProductTag = (
  tags: string[],
): Extract<ProductTag, Availability> => {
  if (tags.includes('Sold out')) {
    return 'Sold out';
  }
  if (tags.includes('Coming soon')) {
    return 'Coming soon';
  }
  return 'Available';
};

export const selectMediumTag = (tags: string[]): PrintMedium => {
  // The tag for print medium is not consistently labeled in Shopify, unlike the Product tags.
  let lowerCaseTags = tags.map((tag) => tag.toLocaleLowerCase());

  if (lowerCaseTags.includes('print edition')) {
    return 'Print edition';
  } else if (lowerCaseTags.includes('sculpture edition')) {
    return 'Sculpture edition';
  } else if (lowerCaseTags.includes('studio works')) {
    return 'Studio works';
  } else if (lowerCaseTags.includes('collectible')) {
    return 'Collectible';
  } else if (lowerCaseTags.includes('nfts')) {
    return 'NFTs';
  }
  return 'Other';
};

export const tagsToExcludeCountries = (tags: string[]): CountryCode[] => {
  return tags
    .filter((tag) => tag.toLowerCase().startsWith('exclude_price_threshold:'))
    .map((tag) => tag.split(':', 2)[1].toUpperCase() as CountryCode);
};

export const mapShopifyProductsToProducts = (
  items: Array<ShopifyProduct>,
): Product[] => {
  return items?.map((item: any) => {
    const tags = (item as ShopifyProduct).tags || [];
    return {
      id: item.id,
      image: item.featuredImage ? item.featuredImage.url : '',
      alt: item.images
        ? item.images.edges
          ? item.images.edges[0]?.node.altText
          : item.images[0]?.node.altText
        : '',
      link: item.onlineStoreUrl || '',
      artist: item.artistName?.value || '',
      title: item.title,
      price: item.priceRange.maxVariantPrice.amount,
      date: item.launchDate?.value || '',
      manifoldListingId: item.manifoldListingId?.value || '',
      auctionFloorPrice: item.auctionFloorPrice?.value || '',
      editionSize: item.editionSize?.value || null,
      availability: selectProductTag(tags),
      medium: selectMediumTag(tags),
      productType: item.productType,
      currencyCode: item.priceRange.maxVariantPrice.currencyCode,
      createdAt: item.createdAt ? item.createdAt : '',
      releaseType: item.releaseType?.value || 'public',
      fewLeft: tags.includes(PRODUCT_TAG_FEW_LEFT),
      freeShipping: tags.includes(PRODUCT_TAG_FREE_SHIPPING),
      samePrice: tags.includes(PRODUCT_TAG_SAME_PRICE),
      pinnedOnHomepage: tags.includes(PRODUCT_TAG_PINNED_ON_HOMEPAGE),
      noresaleAgreement: tags.includes(PRODUCT_TAG_NO_RESALE_AGREEMENT),
      handFinished: tags.includes(PRODUCT_TAG_HANDFINISHED),
      noTaxCharged: tags.includes(PRODUCT_TAG_NO_TAX_CHARGED),
      allowMultipleProducts: tags.includes(PRODUCT_TAG_ALLOW_MULTIPLE_ITEMS),
      supportOneTapCheckout: tags.includes(PRODUCT_TAG_ONE_TAP_CHECKOUT),
      enquireOnlyNoPrice: tags.includes(PRODUCT_TAG_ENQUIRE_ONLY_NO_PRICE),
      launchDate: item.launchDate?.value ?? null,
      closingDate: item.closingDate?.value ?? null,
      cryptoPrice: item.cryptoPrice?.value ?? null,
      full_slug: item.handle,
      excludeFromRegions: tagsToExcludeCountries(tags),
      framingOffered: item.framingOffered?.value || null,
    } as Product;
  });
};

export const mapProductsToMultiCurrencyProducts = (
  products: MultiCurrencyProduct[],
  priceProductsGBP: any[],
  priceProductsEUR: any[],
  priceProductsUSD: any[],
) => {
  return products.map((product) => {
    product.image ||= 'data:,';
    product.alt ||= '';
    product.priceGBP =
      priceProductsGBP
        .find((work) => work.node.id === product.id)
        ?.node.priceRange.maxVariantPrice.amount.toString() || '';
    product.priceEUR =
      priceProductsEUR
        .find((work) => work.node.id === product.id)
        ?.node.priceRange.maxVariantPrice.amount.toString() || '';
    product.priceUSD =
      priceProductsUSD
        .find((work) => work.node.id === product.id)
        ?.node.priceRange.maxVariantPrice.amount.toString() || '';
    return product;
  });
};
export const addSlugToProducts = (
  products: Product[],
  storyblokProducts: Product[],
): Product[] => {
  return products.map((product: Product, index: number) => {
    return {
      ...product,
      full_slug: `${storyblokProducts[index].full_slug}`,
    };
  });
};

export const sortProducts = (
  products: Array<Product>,
  order: 'DESC' | 'ASC',
) => {
  if (order === 'ASC') {
    return products.sort(combineSorters([sortByDateAsc, sortByArtistName]));
  } else {
    return products.sort(combineSorters([sortByDateDesc, sortByArtistName]));
  }
};

export const sortByDateAsc = (a: Product, b: Product) => {
  return a.date && b.date ? a.date.localeCompare(b.date) : 0;
};

export const sortByDateDesc = (a: Product, b: Product) => {
  return a.date && b.date ? b.date.localeCompare(a.date) : 0;
};

export const sortByArtistName = (a: Product, b: Product) => {
  return a.artist.localeCompare(b.artist);
};

export const sortByAvailability = (a: Product, b: Product) => {
  if (a.availability === 'Available') return -1;
  if (b.availability === 'Available') return 1;
  return 0;
};

export const cmToIn = (cm: number) => {
  return cm / 2.54;
};

export const filterProducts = (
  products: Product[],
  filters: {
    availability?: AvailabilityFilter;
    finishes?: FinishFilter[];
    mediums?: MediumFilter[];
    releaseTypes?: ReleaseFilter[];
    shipping?: ShippingFilter;
  },
) => {
  let filtered = products;

  // Availability filter
  if (filters.availability !== undefined && filters.availability.length > 0) {
    filtered = filtered.filter((p) => {
      return (
        filters.availability === 'All' ||
        (p.availability?.toLocaleLowerCase() === 'available' &&
          filters.availability === 'Available') ||
        (p.availability?.toLocaleLowerCase() === 'coming soon' &&
          filters.availability === 'Upcoming')
      );
    });
  }

  // Shipping filter
  if (filters.shipping === 'Free') {
    filtered = filtered.filter(({ freeShipping }) => freeShipping);
  }

  // Medium filter
  if (filters.mediums !== undefined && filters.mediums.length > 0) {
    filtered = filtered.filter((p) => {
      return (
        filters.mediums?.includes('All') ||
        (p.medium?.toLocaleLowerCase() === 'print edition' &&
          filters.mediums?.includes('Print editions')) ||
        (p.medium?.toLocaleLowerCase() === 'collectible' &&
          filters.mediums?.includes('Collectibles')) ||
        (p.medium?.toLocaleLowerCase() === 'sculpture edition' &&
          filters.mediums?.includes('Sculpture editions')) ||
        (p.medium?.toLocaleLowerCase() === 'studio works' &&
          filters.mediums?.includes('Studio works')) ||
        (p.medium?.toLocaleLowerCase() === 'nfts' &&
          filters.mediums?.includes('NFTs'))
      );
    });
  }

  // Finishes
  if (filters.finishes !== undefined && filters.finishes.length > 0) {
    filtered = filtered.filter((p) => {
      return p.handFinished && filters.finishes?.includes('Hand-finished');
    });
  }

  // Release type
  if (filters.releaseTypes !== undefined && filters.releaseTypes.length > 0) {
    filtered = filtered.filter((p) => {
      return (
        filters.releaseTypes?.includes('All') ||
        (p.releaseType === 'public' &&
          filters.releaseTypes?.includes('Standard release')) ||
        (p.releaseType === 'private' &&
          filters.releaseTypes?.includes('Available by enquiry')) ||
        (p.releaseType === 'draw' &&
          filters.releaseTypes?.includes('Draw release')) ||
        (p.releaseType === 'timed' &&
          filters.releaseTypes?.includes('Timed release'))
      );
    });
  }

  return filtered;
};

export function getProductBenefits<T extends ProductPurchaseDetails>(
  product: ProductStoryblok | ProductV2Storyblok,
  purchasing: ProductPurchasingController<T>,
  country: CountryCode,
  draw?: DrawByProductId,
): ProductBenefits {
  // Configured in Shopify
  const hasResaleAgreement = Boolean(!purchasing.product?.noResaleAgreement);
  const hasTwoYearCoaDispatch = Boolean(
    purchasing.product?.tags.includes(PRODUCT_TAG_COA_2_YEAR_DISPATCH),
  );
  const hasFreeShipping = Boolean(
    purchasing.product?.tags.includes(PRODUCT_TAG_FREE_SHIPPING),
  );
  const hasPaypalEnabled = !purchasing?.product?.tags.some(
    (tag) =>
      tag === PRODUCT_TAG_DISABLE_PAYPAL || tag === PRODUCT_TAG_DISABLE_EXPRESS,
  );
  const hasCryptoEnabled = !purchasing?.product?.tags.includes(
    PRODUCT_TAG_DISABLE_CRYPTO,
  );
  const hasCryptoDrawEnabled = draw?.cryptoComEnabled ?? false;
  const hasKlarnaEnabled =
    KLARNA_COUNTRIES.includes(country) &&
    purchasing.product?.price !== undefined &&
    purchasing.product?.price < KLARNA_MAX_IN_EUROS;
  const hasTaxesAndCharges = !purchasing?.product?.tags.includes(
    PRODUCT_TAG_NO_TAX_CHARGED,
  );
  const isOpenEdition = Boolean(
    purchasing.product?.tags.includes(PRODUCT_TAG_OPEN_EDITION),
  );
  const allowMultipleProducts = Boolean(
    purchasing?.product?.tags.includes(PRODUCT_TAG_ALLOW_MULTIPLE_ITEMS),
  );

  // TODO: Remove after Christmas Delivery Period
  const hasChristmasDelivery = purchasing?.product?.tags.includes(
    PRODUCT_TAG_CHRISTMAS_DELIVERY_2022,
  );

  // Configure in Storyblok
  const hasCOA = purchasing.product?.tags.includes(PRODUCT_TAG_NO_COA) ?? false;
  const hasFramingIncluded = framedByDefault(
    purchasing?.product?.framingOffered,
  );
  const hasWoodenBox = !product.noWoodenBox;

  return {
    hasCOA,
    hasTwoYearCoaDispatch,
    hasCryptoEnabled,
    hasCryptoDrawEnabled,
    hasFramingIncluded,
    hasFreeShipping,
    hasKlarnaEnabled,
    hasPaypalEnabled,
    hasResaleAgreement,
    hasTaxesAndCharges,
    hasWoodenBox,
    isOpenEdition,
    hasChristmasDelivery,
    allowMultipleProducts,
  };
}

export const hasFewLeftTag = (tags: string[]) => {
  return tags.includes(PRODUCT_TAG_FEW_LEFT);
};

export const isFewLeft = (product: Product) => product.fewLeft;
export const isSoldOut = (product: Product) =>
  product.availability === 'Sold out';
export const isAvailable = (product: Product) =>
  product.availability === 'Available';
export const isUpcoming = (product: Product) =>
  product.availability === 'Coming soon';
export const isTimedRelease = (product: Product) =>
  product.releaseType === 'timed';
export const isDrawRelease = (product: Product) =>
  product.releaseType === 'draw';
export const isPrivateRelease = (product: Product) =>
  product.releaseType === 'private';
export const isAuctionRelease = (product: Product) =>
  product.releaseType === 'auction' ||
  product.releaseType === 'nft_ranked_auction';
export const isReleaseTypeAuction = (releaseType: string) =>
  releaseType === 'auction' || releaseType === 'nft_ranked_auction';

export const isInNext24Hours = (date: Date) =>
  differenceInHours(date, new Date(), { roundingMethod: 'ceil' }) <= 24;

export const isInNextHours = (date: Date, hours: number) =>
  differenceInHours(date, new Date(), { roundingMethod: 'ceil' }) <= hours;

export const isInNextSixDays = (date: Date) =>
  differenceInHours(date, new Date(), { roundingMethod: 'ceil' }) <= 144;

export const augmentFrames = (
  framingOptions: Array<StoryblokFrame>,
  shopifyFrames: Array<ShopifyFrameProduct>,
  frameIncluded?: boolean,
): Array<StoryblokFrame> | Array<AugmentedShopifyFrame> => {
  if (frameIncluded) {
    return framingOptions;
  }
  const shopifyIds = (shopifyFrames || []).map((frame) => frame.id);

  return framingOptions
    .filter(
      (frame) =>
        frame.frameId &&
        shopifyIds.includes(shopifyAdminIdToGraphqlId(frame.frameId)),
    )
    .map((frame: StoryblokFrame) => {
      const shopifyFrame = shopifyFrames.filter(
        (f) => f.id === shopifyAdminIdToGraphqlId(frame.frameId!),
      )[0];

      const cheapestVariant = getFrameBaseVariant(shopifyFrame);

      return {
        id: shopifyFrame.id,
        price: cheapestVariant.price,
        currency: shopifyFrame.currency,
        name: frame.name,
        description: '',
        frameFinish: frame.frameFinish,
        frameImage: frame.frameImage,
        frameImageComposite:
          frame.productCompositeImage?.[0]?.component === 'image'
            ? frame.productCompositeImage?.[0].image
            : undefined,
        variants: shopifyFrame.variants,
        availableForSale: shopifyFrame.variants.some((v) => v.availableForSale),
        quantityToSell: frame?.quantityPerWork || 1,
      };
    })
    .filter(Boolean);
};

export type ProductState = 'upcoming' | 'available' | 'closed';

export const customPropertyByKey = (
  attrs: ShopifyProductToCheckout['customProperties'],
  key: string,
) => {
  return attrs?.find((attr) => attr.key === key)?.value || '';
};

export const hasSelectableFrame = (
  product: ProductType | undefined,
  frameIncluded: boolean,
): boolean => {
  const hasFramingOptions = Boolean((product?.frameOptions?.length ?? 0) > 0);

  return Boolean(hasFramingOptions && !frameIncluded);
};

export const getSkuFromVariants = (variants: { sku: string }[]) => {
  const [variant] = variants;
  const [part1, part2] = variant.sku.split('-');
  if (part1 && part2) {
    return `${part1}-${part2}`;
  }
  return null;
};
