// Redirect to avantarte.com if not on localhost or avantarte.com domains.
// This is to prevent malicious use of rewrite proxies.

import { isBrowser } from '@utils/isBrowser';

// Originally this is to prevent https://panel.artmenco.com from phishing attempts on our website.
// TODO: Obfuscate this code to make it harder to remove from the served assets in case they created a workaround.
if (isBrowser()) {
  const hostname = window.location.hostname;

  if (
    hostname !== 'localhost' &&
    hostname !== 'avantarte.com' &&
    hostname !== 'arte.bio' &&
    !hostname.endsWith('.avantarte.com') &&
    !hostname.endsWith('.avantarte.dev') // can be dropped later, used for CDN testing
  ) {
    window.location.replace('https://avantarte.com');
  }
}

export {};
