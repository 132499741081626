import { ApolloClient } from '@apollo/client';
import { ALL_ARTISTS_QUERY } from '../apollo/storyblok/queries';
import type { ArtistsGateway } from '../types/Artists';

const ARTISTS_PER_PAGE = 25;

export type ArtistsResponse = {
  ArtistItems: {
    total: number;
    items: {
      full_slug: string;
      slug: string;
      uuid: string;
      created_at: string;
      first_published_at: string | null;
      content: {
        name: string;
        hideCollaborations: boolean | null;
        excludeFromIndex?: boolean;
        thumbnail: {
          filename: string;
          alt: string | null;
          focus: string | null;
        };
        plannedReleases: Array<{
          releaseDate?: string;
          releaseType: StoryblokDatatypeMedium[];
          editionSize?: number;
        }>;
        studioGallery?:
          | {
              component: string;
              image?: {
                filename: string;
              };
            }[]
          | null;
      };
    }[];
  };
};

export class StoryblokArtistsGateway implements ArtistsGateway {
  constructor(private client: ApolloClient<any>) {}

  async allArtists(): Promise<ArtistCard[]> {
    let fetchedArtists = [];
    let totalArtists = null;
    let page = 1;

    while (totalArtists === null || fetchedArtists.length < totalArtists) {
      const { total, artists: nextArtists } = await this.artists({ page });
      fetchedArtists.push(...nextArtists);
      totalArtists = total;
      page += 1;
    }

    return fetchedArtists;
  }

  private async artists(options: { page: number }) {
    const {
      data: {
        ArtistItems: { total, items },
      },
    } = await this.client.query<ArtistsResponse>({
      query: ALL_ARTISTS_QUERY,
      variables: {
        page: options.page,
        per_page: ARTISTS_PER_PAGE,
      },
    });

    const artists = items.map((artist) => {
      const galleryImages = (artist.content.studioGallery || [])
        .filter((item) => item.component !== 'Video')
        .map((item) => item.image?.filename)
        .filter((item) => item !== undefined)
        .slice(1);

      return {
        galleryImages: galleryImages as string[],
        uuid: artist.uuid,
        alt: artist.content.thumbnail.alt,
        focus: artist.content.thumbnail.focus,
        image: artist.content.thumbnail.filename,
        hideCollaborations: artist.content.hideCollaborations,
        link: artist.full_slug,
        slug: artist.slug,
        name: artist.content.name,
        overline: '',
        createdAt: artist.created_at,
        firstPublishedAt: artist.first_published_at,
        plannedReleases: artist.content.plannedReleases,
        excludeFromIndex: artist.content.excludeFromIndex,
      };
    });

    return {
      artists,
      total,
    };
  }
}
