import mixpanel, { Dict, init } from 'mixpanel-browser';
import {
  ProductClickedOptions,
  JournalClickedOptions,
  ArtistClickedOptions,
  WorksResultsOptions,
  WorkClickedOptions,
  CarouselSlideOptions,
  FooterLinkOptions,
  MultiMediaClickOptions,
  LinkClickedOptions,
  CheckoutOptions,
  PageViewOptions,
  WorkPageViewOptions,
  ArtistPageViewOptions,
  ArtistsResultsOptions,
  GiftCardCheckoutOptions,
  UnlockCodeOptions,
  GiftCardValueOptions,
  AuthenticatedOptions,
  OrderDetailsClickedOptions,
  EmailSubscribeActionOptions,
  SubscribeClickedOptions,
  NftWorkPageViewOptions,
  NavbarLinkClickedOptions,
  WalletConnectButtonOptions,
  WalletConnectErrorOptions,
  DrawEntryOptions,
  DrawClickOptions,
  ClaimClickOptions,
  CheckoutStepOptions,
  TransactionStatusOptions,
  BuyNowClickOptions,
  DrawerOpenedOptions,
  DrawerClosedOptions,
  RevealedOptions,
  TransactionStatusOptions2,
  WalletDrawerClickedOptions,
  ExperimentOptions,
  NftAuctionLeaderBoardClickOptions,
  TrackVariantGalleryOptions,
  TrackMessageDisplayedOptions,
  TrackNftAuctionPlaceBidOptions,
  TrackNftClaimOptions,
  FramingOptions,
  ProductAnalytics,
  ArtistAnalytics,
  JournalAnalytics,
  ProductVariantSwitchOptions,
  AllFiltersClearedOptions,
  FiltersClickedOptions,
  FiltersSelectedOptions,
  FilterClearedOptions,
  LinkCardClicked,
  LinkCardCtaClicked,
  AccordionOptions,
  ClickOptions,
  BannerV3ClickedOptions,
  FeesDrawerUpdateClickedOptions,
  LocaleDrawerUpdatedOptions,
  EnquiryClickedOptions,
  BasketCheckoutOptions,
  OneTapOptions,
  TrackNftPrintServiceFormOptions,
  RelatedDigitalProducts,
  ArtistBioPageViewOptions,
} from '../types/Analytics';
import { captureBreadcrumb, captureError } from './capture';
import { storyblokProductReleaseType } from './storyblokReleaseType';
import {
  ARTIST_PAGE_TYPE,
  JOURNAL_PAGE_TYPE,
  NFT_PRODUCT_PAGE_TYPE,
  NFT_PROJECT_PAGE_TYPE,
  WORK_PAGE_TYPE,
} from '../constants';
import { DigitalCurrencyType } from '../types/web3/product';
import { shopifyGraphqlIdToAdminId } from './shopifyIds';
import { EventStages } from './liveEvents/liveEvents';
import { renderArtistsNamesFromPageContent } from './artists';
import { useEffect } from 'react';
import { normaliseVariantName } from './frames';
import { underscoreToSpace } from './strings';
import { isBrowser as isBrowserUtil } from '@utils/isBrowser';
import { ProductV2Storyblok } from '../types/generated-storyblok';
import {
  ProductPurchaseDetails,
  ProductPurchasingController,
} from '../types/Purchasing';
import { getProductStatus } from '../helpers/purchasing';
import { DrawStage } from '../types/Draw';
import { gtmEvent } from '../scripts/GoogleTagManager';

const getQueryParam = (url: string, param: string) => {
  const paramString = param.replace(/[[]/, '[').replace(/[]]/, ']');
  const regexS = '[?&]' + paramString + '=([^&#]*)';
  const regex = new RegExp(regexS);
  const results = regex.exec(url);

  if (
    results === null ||
    (results && typeof results[1] !== 'string' && (results[1] as any).length)
  ) {
    return '';
  } else {
    return decodeURIComponent(results[1]).replace(/\W/gi, ' ');
  }
};

const campaignParams = () => {
  const campaign_keywords =
    'utm_source utm_medium utm_campaign utm_content utm_term'.split(' ');
  let keywords = '';
  const params: { [key: string]: string } = {};
  const isBrowser = isBrowserUtil();

  for (let index = 0; index < campaign_keywords.length; ++index) {
    if (isBrowser) {
      keywords = getQueryParam(document.URL, campaign_keywords[index]);
      if (keywords.length) {
        params[campaign_keywords[index] + ' [last touch]'] = keywords;
      }
    }
  }

  if (isBrowser && mixpanel && Object.keys(params).length !== 0) {
    mixpanel.register(params);
  }
};

let isProduction = process.env.NODE_ENV === 'production';
if (process.env.NEXT_PUBLIC_MIXPANEL_TOKEN) {
  init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN, {
    debug: !isProduction,
  });

  if (process.env.NEXT_PUBLIC_MIXPANEL_LAST_TOUCH_ENABLED === 'true') {
    campaignParams();
  }
}

export const distinct_id = () => {
  return mixpanel.get_distinct_id();
};

const track = (event: string, properties: Dict): Promise<void> => {
  if (
    !process.env.NEXT_PUBLIC_MIXPANEL_TOKEN ||
    (window && window.location.href.indexOf('storyblok') > 0)
  )
    return Promise.resolve();

  if (
    navigator.userAgent ===
    'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/95.0.4638.69 Safari/537.36'
  ) {
    mixpanel.register({ '$ignore': true });
  }

  if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
    captureBreadcrumb({
      message: event,
      level: 'info',
    });
  }

  // Proxy events to FB events tracking
  gtmEvent(event, properties);

  return new Promise((resolve) => {
    try {
      mixpanel.track(event, properties, () => {
        resolve();
      });
    } catch (e) {
      captureError(e);
    }
  });
};

export const TrackEvent = (event: string, properties: Dict = {}) => {
  return track(event, properties);
};

// temporary method following Collective's structure until the new code comes along
export const TrackLiveEvent = (action: string, properties?: Dict) => {
  track('live event', { action, ...properties, url: window.location.href });
};

export const TrackLiveEventStageChange = (
  stage: EventStages,
  timeSpentOnStage: Duration | undefined,
  reason: string,
) => {
  TrackLiveEvent('stage change', {
    stage,
    timeSpentOnStage,
    reason,
  });
};

export const TrackEventEntrypoint = (action: string, properties?: Dict) => {
  track('event entrypoint', {
    action,
    ...properties,
    url: window.location.href,
  });
};

export const TrackGtagEvent = (name: string, properties = {}) => {
  gtmEvent(name, properties);
};

export const TrackFramingGuide = (action: string, properties?: Dict) => {
  track('framing guide', { action, ...properties, url: window.location.href });
};

export const TrackReviewsOrder = (action: string, properties?: Dict) => {
  track('reviews order', { action, ...properties, url: window.location.href });
};

export const identifyUser = (account: { email: string; id: string }) => {
  if (process.env.NEXT_PUBLIC_MIXPANEL_TOKEN) {
    mixpanel.identify(account.email);
    mixpanel.people.set({
      $email: account.email,
      $id: account.id,
    });
  }

  gtmEvent('config', {
    user_email: account.email,
    user_id: account.id,
  });
};

const identifyUserByEmailAddress = (email?: string) => {
  if (!process.env.NEXT_PUBLIC_MIXPANEL_TOKEN) return;

  const mixpanelUserId = mixpanel.get_property('$user_id');

  if (email && email !== mixpanelUserId) {
    mixpanel.identify(email);
    mixpanel.people.set({ '$email': email });
  }

  if (email) {
    gtmEvent('config', {
      user_email: email,
    });
  }
};

export const trackWeb3Wallet = (walletAddress: string, chainId: string) => {
  try {
    mixpanel.people.union({
      'wallet address': [walletAddress],
      'chain id': [chainId],
    });
  } catch (e) {
    captureError(e);
  }
};

export const TrackPageView = ({ page, title, properties }: PageViewOptions) => {
  return track(`${page} page viewed`, { title, ...properties });
};

// useTrackPageView is a hook that tracks page views.
// make sure "properties" is memoized if not undefined to avoid running hook
export const useTrackPageView = (
  page: PageViewOptions['page'],
  title?: string,
  properties?: Record<string, unknown>,
) => {
  useEffect(() => {
    TrackPageView({ page, title, properties });
  }, [page, title, properties]);
};

export const TrackWorkPageView = ({
  drawStage,
  page,
  product,
  productStatus,
  productCanBeFramed,
  productPreOrderAvailable,
  frameIncluded,
  frameUpgradeAvailable,
  price,
}: WorkPageViewOptions) => {
  const releaseType = storyblokProductReleaseType(product);

  return track(`${page} page viewed`, {
    'product draw stage': underscoreToSpace(drawStage).toLowerCase(),
    'product id': product.id,
    'product title': product.title,
    ...createProductPriceProperties(
      product.pricesAndAvailability.pricesAndCurrencies,
    ),
    'pre-order available': productPreOrderAvailable,
    'artist name': renderArtistsNamesFromPageContent(product.productArtist),
    'product release date': product.launchDate,
    'product edition size': product.editionSize,
    'product framed by default': frameIncluded,
    'product can be framed': productCanBeFramed,
    'product frame upgrade available': frameUpgradeAvailable,
    'product launch date': product.launchDate,
    'product medium': product.medium,
    'product status': productStatus,
    'product release type': releaseType,
    ecommerce: {
      items: [
        {
          item_id: product.id[0].id,
          item_name: product.title,
          item_brand: renderArtistsNamesFromPageContent(product.productArtist), //artist
          item_category: product.medium?.join(','),
          price,
          quantity: 1,
        },
      ],
    },
  });
};

export const TrackArtistPageView = ({
  page,
  artistName,
  hasUpcomingRelease,
  collaborationsWithArtist,
  hasUpcomingProductPage,
  currentTab,
}: ArtistPageViewOptions) => {
  return track(`${page} page viewed`, {
    'artist name': artistName,
    'has upcoming release': hasUpcomingRelease,
    'collaborations with artist': collaborationsWithArtist,
    'has upcoming product page': hasUpcomingProductPage,
    'current tab': currentTab,
  });
};

export const TrackArtistBioPageView = ({
  page,
  artistName,
  artistId,
  currentTab,
}: ArtistBioPageViewOptions) => {
  return track(`${page} page viewed`, {
    'artist name': artistName,
    'artistId': artistId,
    'current tab': currentTab,
  });
};

export const TrackBannerV3Clicked = ({
  page,
  product,
  artist,
  journal,
  title,
  url,
  type,
  position,
}: BannerV3ClickedOptions) => {
  return track(`${page} page banner clicked`, {
    'banner position': position,
    'banner campaign': '',
    'banner title': title,
    'banner type': type,
    'banner url': url,
    ...(product && transformProductToEventObject(product)),
    ...(artist && transformArtistToEventObject(artist)),
    ...(journal && transformJournalToEventObject(journal)),
  });
};

export const transformProductToEventObject = (
  product: ProductAnalytics,
  prefix?: string,
) => {
  const transformedProduct: Record<string, unknown> = {
    'product id': product.id,
    'product title': product.title,
    'product type': product.type,
    'product release date': product.releaseDate,
    'product release type': product.releaseType,
    'product edition size': product.editionSize,
    'product price': product.price,
    ...createProductPricingProperties(
      product.pricesAndCurrencies,
      product.price,
      product.currencyCode,
    ),
    'product status': product.status,
    'product medium': product.medium,
    'artist name': product.artistName,
    'product draw stage': product.drawStage,
    'pre-order available': product.isPreOrderAvailable,
  };

  if (prefix) {
    // Converts `product id` -> `prefix product id`
    for (const key of Object.keys(transformedProduct)) {
      transformedProduct[`${prefix} ${key}`] = transformedProduct[key];
      delete transformedProduct[key];
    }
  }

  return transformedProduct;
};

const transformArtistToEventObject = (artist: ArtistAnalytics) => {
  return {
    'artist id': artist.id,
    'artist name': artist.name,
    'artist has upcoming release': artist.hasUpcomingRelease,
    'artist has upcoming product page': artist.hasUpcomingProductPage,
    'artist number of collaborations': artist.collaborations,
  };
};

const transformJournalToEventObject = (journal: JournalAnalytics) => {
  return {
    'journal title': journal.title,
  };
};

export const TrackProductClicked = ({
  page,
  context,
  id,
  productTitle,
  artist,
  productType,
  releaseDate,
  productUrl,
  position,
  editionSize,
  price,
  currencyCode,
  ProductAvailibity,
}: ProductClickedOptions) => {
  return track(`${page} page product clicked`, {
    'context': context,
    'product position': position,
    'product id': id,
    'product title': productTitle,
    'product type': productType,
    'product artist': artist,
    'product url': productUrl,
    'product release date': releaseDate,
    'product edition size': editionSize,
    'product price': price,
    ...createProductPriceProperty(price, currencyCode),
    'product status': ProductAvailibity,
  });
};

export const TrackJournalClicked = ({
  page,
  context,
  title,
  url,
  position,
  featuredPageTitle,
}: JournalClickedOptions) => {
  return track(`${page} page ${context} clicked`, {
    'item position': position,
    'journal title': title,
    'journal url': url,
    ...(featuredPageTitle && { 'featured title': featuredPageTitle }),
  });
};

export const TrackLinkCardClicked = ({
  placement,
  title,
  url,
}: LinkCardClicked) =>
  track('home page link card clicked', { placement, title, url });

export const TrackLinkCardCtaClicked = ({ cta }: LinkCardCtaClicked) =>
  track('home page link card cta clicked', { cta });

export const TrackLinkCardSalesCtaClicked = ({ cta }: LinkCardCtaClicked) =>
  track('home page sales support link', { cta });

export const TrackArtistClicked = ({
  page,
  context,
  id,
  artistName,
  artistUrl,
  position,
  searchTerm,
  mediumFilter,
  categoryFilter,
  orderFilter,
  pageNumber,
  resultCount,
}: ArtistClickedOptions) => {
  return track(`${page} page ${context} clicked`, {
    'artist position': position ? position + 1 : 0,
    'artist id': id,
    'artist name': artistName,
    'artist url': artistUrl,
    'category filter': categoryFilter?.map((f) => f.toLowerCase()),
    'medium filter': mediumFilter?.map((f) => f.toLowerCase()),
    'order filter': orderFilter?.toLowerCase(),
    'total artists': resultCount,
    'page number': pageNumber,
    ...(searchTerm && { 'index search query': searchTerm.toLowerCase() }),
  });
};

export const TrackLinkClicked = ({
  page,
  context,
  title,
  url,
  isExternal,
  productTitle,
  productPrice,
  productCurrencyCode,
  productPricesAndCurrencies,
  artistName,
}: LinkClickedOptions) => {
  return track(`${page} page ${context} link clicked`, {
    'link title': title,
    'link url': url,
    'is external': isExternal,
    'product title': productTitle,
    ...createProductPricingProperties(
      productPricesAndCurrencies,
      productPrice,
      productCurrencyCode,
    ),
    'artist name': artistName,
  });
};

export const TrackClick = ({
  page,
  context,
  title,
  artistName,
}: ClickOptions) => {
  return track(`${page} page ${context} clicked`, {
    'title': title,
    'artist name': artistName,
  });
};

export const TrackNavbarLinkClicked = ({
  context,
  title,
}: NavbarLinkClickedOptions) => {
  return track(`navbar link clicked`, {
    'link title': title,
    'link context': context,
  });
};

export const TrackArtistsResults = ({
  page,
  pageNumber,
  searchTerm,
  categoryFilter,
  mediumFilter,
  orderFilter,
  resultCount,
}: ArtistsResultsOptions) => {
  return track(`${page} page results updated`, {
    'page number': pageNumber,
    'category filter': categoryFilter?.map((f) => f.toLowerCase()),
    'medium filter': mediumFilter?.map((f) => f.toLowerCase()),
    'order filter': orderFilter?.toLowerCase(),
    'total artists': resultCount,
    ...(searchTerm && { 'index search query': searchTerm.toLowerCase() }),
  });
};

export const TrackWorksResults = ({
  page,
  searchTerm,
  results,
  tab,
  availibilityFilter,
  finishFilters,
  mediumFilter,
  orderFilter,
  releaseFilters,
  totalPages,
}: WorksResultsOptions) => {
  const resultTitle = results.map(({ title }) => title.toLowerCase());
  return track(`${page} page results updated`, {
    'product results': resultTitle,
    'total products': results.length,
    'total pages': totalPages,
    'tab': tab,
    'availibility filter': availibilityFilter.map((f) => f?.toLowerCase()),
    'finish filters': finishFilters.map((f) => f.toLowerCase()),
    'medium filter': mediumFilter.map((f) => f.toLowerCase()),
    'order filter': orderFilter.toLowerCase(),
    'release filters': releaseFilters.map((f) => f.toLowerCase()),
    ...(searchTerm && { 'index search query': searchTerm.toLowerCase() }),
  });
};

export const TrackWorkClicked = ({
  page,
  id,
  productTitle,
  artist,
  price,
  productPricesAndCurrencies,
  productCurrencyCode,
  productPrice,
  productType,
  productStatus,
  releaseDate,
  releaseType,
  productUrl,
  searchTerm,
  availibilityFilter,
  finishFilters,
  mediumFilter,
  orderFilter,
  releaseFilters,
  tab,
  position,
  pageNumber,
}: WorkClickedOptions) => {
  return track(`${page} page product clicked`, {
    'product position': position ? position + 1 : 0,
    'product id': id,
    'product title': productTitle,
    'product price': price,
    ...createProductPricingProperties(
      productPricesAndCurrencies,
      productPrice,
      productCurrencyCode,
    ),
    'product type': productType,
    'product artist': artist,
    'product url': productUrl,
    'product release date': releaseDate,
    'product release type': releaseType,
    'product status': productStatus,
    'availibility filter': availibilityFilter.map((f) => f?.toLowerCase()),
    'finish filters': finishFilters.map((f) => f?.toLowerCase()),
    'medium filter': mediumFilter.map((f) => f?.toLowerCase()),
    'order filter': orderFilter?.toLowerCase(),
    'release filters': releaseFilters.map((f) => f?.toLowerCase()),
    'tab': tab,
    'page number': pageNumber,
    ...(searchTerm && { 'index search query': searchTerm.toLowerCase() }),
  });
};

export const TrackFooterLink = ({
  linkTitle,
  linkUrl,
  isExternal,
}: FooterLinkOptions) => {
  return track(`footer link clicked`, {
    'link title': linkTitle,
    'link url': linkUrl,
    'is external': isExternal,
  });
};

export const TrackMultiMediaClick = ({
  page,
  pageTitle,
  context,
  type,
  title,
  position,
  durationInSeconds,
}: MultiMediaClickOptions) => {
  return track(`${page} page media clicked`, {
    'context': context,
    ...(page === WORK_PAGE_TYPE && { 'product title': pageTitle }),
    ...(page === ARTIST_PAGE_TYPE && { 'artist name': pageTitle }),
    ...(page === JOURNAL_PAGE_TYPE && { 'journal title': pageTitle }),
    ...(page === NFT_PRODUCT_PAGE_TYPE && { 'nft product title': pageTitle }),
    ...(page === NFT_PROJECT_PAGE_TYPE && { 'nft project title': pageTitle }),
    'page title': pageTitle,
    'media type': type,
    'media title': title,
    'media position': position,
    'media duration in seconds': durationInSeconds,
  });
};

export const TrackBuynowClick = ({
  product,
  isFramed,
  canBeFramed,
  crossSellOffered,
  frameGlazingName,
  frameUpgradeAvailable,
  price,
  quantity,
}: BuyNowClickOptions) => {
  return track('buy now clicked', {
    'cross sell offered': crossSellOffered,
    'product title': product.title,
    'product medium': product.medium,
    ...createProductPriceProperties(
      product.pricesAndAvailability.pricesAndCurrencies,
    ),
    'artist name': renderArtistsNamesFromPageContent(product.productArtist),
    'product edition size': product.editionSize,
    'product frame chosen': isFramed,
    'product can be framed': canBeFramed,
    'product frame upgrade available': frameUpgradeAvailable,
    'product frame glazing': frameGlazingName
      ? normaliseVariantName(frameGlazingName)
      : undefined,
    ecommerce: {
      items: [
        {
          item_id: product.id[0].id,
          item_name: product.title,
          item_brand: renderArtistsNamesFromPageContent(product.productArtist), //artist
          item_category: product.medium?.join(','),
          price,
          quantity,
        },
      ],
    },
  });
};

export const TrackOneTap = ({
  type,
  products,
  country,
  currency,
  accountId,
  errorMessage,
  userEmail,
  amount,
  artistName,
  idempotencyKey,
}: OneTapOptions) => {
  if (type === 'Successful payment' && amount) {
    products?.map((product) => {
      gtmEvent('checkout_complete', {
        product_id: product.productId.split('/').pop()?.toString(),
        product_name: product.productName,
        artist_name: artistName,
        quantity: product.quantity,
        currency,
        digital_wallet: 1,
        user_email: userEmail,
        total_amount: amount / 100,
        order_id: null,
        checkout_id: null,
        checkout_complete_count: 1,
        correlation_id: idempotencyKey,
      });
    });
  }

  return track('one tap', {
    'type': type,
    'products': products,
    'country': country,
    'currency': currency,
    'accountId': accountId,
    'errorMessage': errorMessage,
  });
};

export const TrackCheckout = ({
  productTitle,
  productCurrencyCode,
  productPrice,
  productPricesAndCurrencies,
  numberOfEditions,
  productId,
  productIdMain,
  isFramed,
  frameId,
  framePrice,
  canBeFramed,
  frameUpgradeAvailable,
  quantity,
  checkoutId,
  frameVariantTitle,
  artist,
  productCategory,
}: CheckoutOptions) => {
  const lineItemValue = productPrice + (framePrice || 0);
  const orderValue = quantity * lineItemValue;

  gtmEvent('clicked', {
    event_category: 'Checkout',
    event_label: productTitle,
  });

  gtmEvent('checkout_clicked', {
    product_id: tryFormatShopifyId(productId),
    order_value: orderValue,
    order_value_currency: productCurrencyCode,
    quantity: quantity,
    shopify_checkout_id: tryFormatShopifyId(checkoutId),
  });

  // duplicated for testing
  gtmEvent('checkout_clicked', {
    product_id: tryFormatShopifyId(productId),
    order_value: orderValue,
    order_value_currency: productCurrencyCode,
    quantity: quantity,
    shopify_checkout_id: tryFormatShopifyId(checkoutId),
  });

  return track(`checkout initiated`, {
    'product title': productTitle,
    'product price': productPrice,
    ...createProductPricingProperties(
      productPricesAndCurrencies,
      productPrice,
      productCurrencyCode,
    ),
    'product id': productId,
    'product edition size': numberOfEditions,
    'product can be framed': canBeFramed,
    'product frame upgrade available': frameUpgradeAvailable,
    'product frame chosen': isFramed,
    'checkout currency': productCurrencyCode,
    'checkout total':
      isFramed && framePrice ? framePrice + productPrice : productPrice,
    'frame id': frameId,
    'frame price': framePrice,
    'product_frame_glazing': frameVariantTitle,
    ecommerce: {
      items: [
        {
          item_id: tryFormatShopifyId(productIdMain),
          item_name: productTitle,
          item_brand: artist,
          item_category: productCategory?.join(','),
          price: productPrice,
          quantity,
        },
      ],
    },
  });
};

export const TrackBasketCheckout = ({
  basket,
  checkoutId,
  customAttributes,
}: BasketCheckoutOptions) => {
  const orderValue = basket.reduce(
    (total, product) => total + product.productPrice * product.quantity,
    0,
  );

  gtmEvent('clicked', {
    event_category: 'Checkout',
    event_label: 'basket',
  });

  gtmEvent('basket_checkout_clicked', {
    products: basket.map((product) => ({
      product_id: tryFormatShopifyId(product.productId),
      quantity: product.quantity,
      custom_attributes: product.customAttributes,
    })),
    order_value: orderValue,
    shopify_checkout_id: tryFormatShopifyId(checkoutId),
    custom_attributes: customAttributes,
  });

  return track(`basket checkout initiated`, {
    'products': basket.map((product) => ({
      'product title': product.productTitle,
      'product price': product.productPrice,
      'product id': product.productId,
      'custom attributes': product.customAttributes,
    })),
    'checkout total': orderValue,
    'custom attributes': customAttributes,
    ecommerce: {
      items: basket.map((product) => ({
        item_id: product.productId,
        item_name: product.productTitle,
        // item_brand: ???,
        item_category: product.productMedium?.join(','),
        price: product.productPrice,
        quantity: product.quantity,
      })),
    },
  });
};

export const TrackCarouselSlide = ({
  page,
  pageTitle,
  context,
  title,
  position,
  carouselLength,
}: CarouselSlideOptions) => {
  return track(`${page} page carousel slide`, {
    'context': context,
    ...(page === ARTIST_PAGE_TYPE && { 'artist name': pageTitle }),
    'carousel position': position ? position + 1 : 0,
    'carousel title': title,
    'carouselLength': carouselLength,
  });
};

export const TrackGiftCardCheckout = ({
  giftCardVariantId,
  giftCardValue,
  customization,
}: GiftCardCheckoutOptions) => {
  return track(`Track Gift Card Checkout`, {
    'gift card variant Id': giftCardVariantId,
    'gift card value': giftCardValue,
    'customization': customization,
  });
};

export const TrackGiftCardValueSelected = ({
  giftCardVariantId,
  giftCardValue,
}: GiftCardValueOptions) => {
  return track(`Track Gift Card Value selected`, {
    'gift card variant Id': giftCardVariantId,
    'gift card value': giftCardValue,
  });
};

export const TrackSubscribeClicked = ({
  type,
  emailAddress,
  artistName,
  productTitle,
  productPricesAndCurrencies,
  productPrice,
  productCurrencyCode,
  productStatus,
  productReleaseType,
  buttonLocation,
  userAgent,
  mode,
}: SubscribeClickedOptions) => {
  if (emailAddress) {
    identifyUserByEmailAddress(emailAddress);
  }

  if (productTitle) {
    gtmEvent(type, {
      event_category: 'Product_Signup',
      event_label: productTitle,
    });
  } else {
    gtmEvent(type, {
      event_category: 'Artist_Signup',
      event_label: artistName,
    });
  }

  return track(`subscribe ${type}`, {
    'product title': productTitle,
    ...createProductPricingProperties(
      productPricesAndCurrencies,
      productPrice,
      productCurrencyCode,
    ),
    'product status': productStatus,
    'product release type': productReleaseType,
    'artist name': artistName,
    'button location': buttonLocation,
    'user agent': userAgent,
    'mode': mode,
  });
};

export const TrackEnquiryClicked = ({
  type,
  emailAddress,
  artistName,
  productTitle,
  productPricesAndCurrencies,
  productPrice,
  productCurrencyCode,
  productStatus,
  productReleaseType,
  buttonLocation,
  userAgent,
  page,
  context,
}: EnquiryClickedOptions) => {
  if (emailAddress) {
    identifyUserByEmailAddress(emailAddress);
  }

  return track(`${context} ${type}`, {
    'page': page,
    'product title': productTitle,
    ...createProductPricingProperties(
      productPricesAndCurrencies,
      productPrice,
      productCurrencyCode,
    ),
    'product status': productStatus,
    'product release type': productReleaseType,
    'artist name': artistName,
    'button location': buttonLocation,
    'user agent': userAgent,
  });
};

export const TrackUnlockCode = ({
  type,
  productTitle,
  productPricesAndCurrencies,
  productPrice,
  productCurrencyCode,
  code,
  expired,
}: UnlockCodeOptions) => {
  return track(`unlock code ${type}`, {
    'product title': productTitle,
    ...createProductPricingProperties(
      productPricesAndCurrencies,
      productPrice,
      productCurrencyCode,
    ),
    'code': code,
    'expired': expired,
  });
};

export const TrackOrderDetailsClicked = ({
  orderNumber,
  page,
  context,
}: OrderDetailsClickedOptions) => {
  return track(`${context}: order details clicked`, {
    'orderId': orderNumber,
    'page': page,
  });
};

export const TrackProductVariantSwitchClicked = ({
  page,
  context,
  position,
  variantOption,
  sourceProduct,
  targetProduct,
}: ProductVariantSwitchOptions) => {
  return track(`${page} page ${context} clicked`, {
    ...transformProductToEventObject(sourceProduct, 'source'),
    ...transformProductToEventObject(targetProduct, 'target'),
    'position': position,
    'variant option': variantOption,
  });
};
export const TrackAuthenticated = ({ email }: AuthenticatedOptions) => {
  identifyUserByEmailAddress(email);

  return track('user authenticated', {});
};

export const TrackEmailSubscription = ({
  type,
  artistId,
  artistName,
  productId,
  productTitle,
  productPricesAndCurrencies,
  productPrice,
  productCurrencyCode,
  generalMailList,
  productStatus,
  productReleaseType,
  mode,
  emailAddress,
  hasJustSignedUp,
  buttonLocation,
  error,
  userAgent,
  custom,
  releaseName,
  trackEvent = true,
}: EmailSubscribeActionOptions) => {
  if (emailAddress) {
    identifyUserByEmailAddress(emailAddress);
  }

  if (type === 'success') {
    if (releaseName) {
      gtmEvent('release_signup', {
        artist_id: artistId,
        release_name: releaseName,
        release_artist: artistName,
      });
    }
    if (productTitle) {
      gtmEvent('product_signup', {
        artist_id: artistId,
        product_id: tryFormatShopifyId(productId),
        product: productTitle,
        release_artist: artistName,
      });
    } else if (artistName) {
      gtmEvent('artist_signup', {
        artist: artistName,
        artist_id: artistId,
      });
    } else if (generalMailList) {
      gtmEvent('general_signup', {
        'button location': buttonLocation,
      });
    }
  }

  if (trackEvent) {
    return track(`subscribe ${type}`, {
      'artist name': artistName,
      'product title': productTitle,
      ...createProductPricingProperties(
        productPricesAndCurrencies,
        productPrice,
        productCurrencyCode,
      ),
      'general mail list subscribed': generalMailList,
      'product status': productStatus,
      'product release type': productReleaseType,
      'mode': mode,
      'button location': buttonLocation,
      'error': error,
      'user Agent': userAgent,
      'has just signed up': hasJustSignedUp,
      ...custom,
    });
  }
  return Promise.resolve();
};

export const TrackNftWorkPageView = ({
  product,
  artistName,
  page,
  claimType,
}: NftWorkPageViewOptions) => {
  return track(`${page} page viewed`, {
    'artist name': artistName,
    'product edition size': product.totalSupply,
    'product id': product._uid,
    'product title': product.title,
    'product price': product.price,
    ...createProductPriceProperty(product.price, product.digitalCurrency),
    'product launchDate': product.startDate,
    'product releaseDate': product.closeDate,
    'product hasEarlyAccess': product.hasEarlyAccess,
    'product earlyAccessStartDate': product.earlyAccessStartDate,
    'product earlyAccessCloseDate': product.earlyAccessCloseDate,
    'product chainId': product.chainId,
    'product currency': product.digitalCurrency,
    'product blockchain': product.blockChain,
    'product medium': ['nft'],
    'product release type': 'public',
    'claim type': claimType,
  });
};

export const TrackWalletConnectButton = ({
  page,
  productTitle,
  productPricesAndCurrencies,
  productPrice,
  productCurrencyCode,
  productId,
  releaseType,
  drawId,
  context,
}: WalletConnectButtonOptions) => {
  return track(`connect wallet clicked`, {
    'page type': page,
    'product title': productTitle,
    ...createProductPricingProperties(
      productPricesAndCurrencies,
      productPrice,
      productCurrencyCode,
    ),
    'product id': productId,
    'release type': releaseType,
    'draw id': drawId,
    'context': context,
  });
};

export const TrackWalletConnectProvider = (provider: string) => {
  return track(`connect wallet provider chosen`, {
    'provider': provider,
  });
};

export const TrackWalletDrawerClicked = ({
  linkTitle,
  linkContext,
}: WalletDrawerClickedOptions) => {
  return track(`wallet drawer clicked`, {
    'link title': linkTitle,
    'link context': linkContext,
  });
};

export const TrackWalletConnectError = ({
  errorType,
  errorMessage,
}: WalletConnectErrorOptions) => {
  return track(`connect wallet error`, {
    'error type': errorType,
    'error message': errorMessage,
  });
};

export const TrackDrawClick = ({
  productTitle,
  productPricesAndCurrencies,
  productPrice,
  productCurrencyCode,
  productId,
  product,
  drawId,
}: DrawClickOptions) => {
  return track(`draw entry clicked`, {
    'product title': productTitle,
    ...createProductPricingProperties(
      productPricesAndCurrencies,
      productPrice,
      productCurrencyCode,
    ),
    'product id': productId,
    'product': product,
    'draw id': drawId,
  });
};
export const TrackDrawEntry = ({
  productTitle,
  productPricesAndCurrencies,
  productPrice,
  productCurrencyCode,
  productId,
  product,
  drawId,
  success,
}: DrawEntryOptions) => {
  return track(`draw entry success`, {
    'product title': productTitle,
    ...createProductPricingProperties(
      productPricesAndCurrencies,
      productPrice,
      productCurrencyCode,
    ),
    'product id': productId,
    'product': product,
    'draw id': drawId,
    'success': success,
  });
};

export const TrackDrawInfoMessageShown = (message: string) => {
  return track(`draw info message shown`, {
    'message': message,
  });
};

export const TrackBockChainError = (message: string) => {
  return track(`blockchain error message`, {
    'message': message,
  });
};

export const TrackCaimClick = ({
  type,
  productTitle,
  productPricesAndCurrencies,
  productPrice,
  productCurrencyCode,
  productId,
  product,
  drawId,
}: ClaimClickOptions) => {
  return track(`claim nft - clicked on ${type}`, {
    'product title': productTitle,
    ...createProductPricingProperties(
      productPricesAndCurrencies,
      productPrice,
      productCurrencyCode,
    ),
    'product id': productId,
    'product': product,
    'draw id': drawId,
  });
};

export const TrackClaimStep = ({
  step,
  productTitle,
  productPricesAndCurrencies,
  productPrice,
  productCurrencyCode,
  productId,
  product,
  drawId,
}: CheckoutStepOptions) => {
  return track(`claim nft - ${step} viewed`, {
    'product title': productTitle,
    ...createProductPricingProperties(
      productPricesAndCurrencies,
      productPrice,
      productCurrencyCode,
    ),
    'product id': productId,
    'product': product,
    'draw id': drawId,
  });
};

export const TrackTransactionStatus = ({
  status,
  productTitle,
  productPricesAndCurrencies,
  productPrice,
  productCurrencyCode,
  productId,
  product,
  drawId,
  hash,
}: TransactionStatusOptions) => {
  return track(`claim nft - transaction ${status}`, {
    'product title': productTitle,
    ...createProductPricingProperties(
      productPricesAndCurrencies,
      productPrice,
      productCurrencyCode,
    ),
    'product id': productId,
    'product': product,
    'draw id': drawId,
    'transaction hash': hash,
  });
};

export const TrackTransactionStatus2 = ({
  status,
  productTitle,
  productPricesAndCurrencies,
  productPrice,
  productCurrencyCode,
  hash,
  object = 'transaction',
  context = 'transaction',
  location,
}: TransactionStatusOptions2) => {
  return track(context, {
    'product title': productTitle,
    ...createProductPricingProperties(
      productPricesAndCurrencies,
      productPrice,
      productCurrencyCode,
    ),
    'transaction hash': hash,
    'status': status,
    'object': object,
    'location': location,
    'product medium': ['nft'],
  });
};

export const TrackExpanded = ({
  page,
  context,
  title,
  url,
  productTitle,
  productCurrencyCode,
  productPrice,
  productPricesAndCurrencies,
  artistName,
}: LinkClickedOptions) => {
  return track(`${page} page ${context} expanded`, {
    'link title': title,
    'link url': url,
    'product title': productTitle,
    ...createProductPricingProperties(
      productPricesAndCurrencies,
      productPrice,
      productCurrencyCode,
    ),
    'artist name': artistName,
  });
};

export const TrackContracted = ({
  page,
  context,
  title,
  url,
  productTitle,
  productCurrencyCode,
  productPrice,
  productPricesAndCurrencies,
  artistName,
}: LinkClickedOptions) => {
  return track(`${page} page ${context} contracted`, {
    'link title': title,
    'link url': url,
    'product title': productTitle,
    ...createProductPricingProperties(
      productPricesAndCurrencies,
      productPrice,
      productCurrencyCode,
    ),
    'artist name': artistName,
  });
};

export const TrackDrawerOpened = ({
  page,
  source,
  context,
  productTitle,
  productCurrencyCode,
  productPrice,
  productPricesAndCurrencies,
  artistName,
}: DrawerOpenedOptions) => {
  const eventParts: string[] = [context, 'drawer opened'];

  if (page) eventParts.unshift(`${page} page`);
  if (source) eventParts.unshift(source);

  return track(eventParts.join(' '), {
    'product title': productTitle,
    ...createProductPricingProperties(
      productPricesAndCurrencies,
      productPrice,
      productCurrencyCode,
    ),
    'artist name': artistName,
  });
};

export const TrackFeesDrawerUpdateClicked = ({
  page,
  productTitle,
  productCurrencyCode,
  productPrice,
  productPricesAndCurrencies,
  artistName,
}: FeesDrawerUpdateClickedOptions) => {
  const eventParts: string[] = ['fees drawer update clicked'];

  if (page) eventParts.unshift(`${page} page`);

  return track(eventParts.join(' '), {
    'product title': productTitle,
    ...createProductPricingProperties(
      productPricesAndCurrencies,
      productPrice,
      productCurrencyCode,
    ),
    'artist name': artistName,
  });
};

export const TrackLocaleDrawerUpdated = ({
  source,
  countryCode,
  currencyCode,
  unitOfMeasurementCode,
}: LocaleDrawerUpdatedOptions) => {
  const eventParts: string[] = ['locale drawer updated'];

  if (source) eventParts.unshift(source);

  return track(eventParts.join(' '), {
    'country code': countryCode,
    'currency code': currencyCode,
    'unit of measurement code': unitOfMeasurementCode,
  });
};

export const TrackDrawerClosed = ({
  page,
  context,
  productTitle,
  productCurrencyCode,
  productPrice,
  productPricesAndCurrencies,
  artistName,
}: DrawerClosedOptions) => {
  const eventParts: string[] = [context, 'drawer closed'];

  if (page) eventParts.unshift(`${page} page`);

  return track(eventParts.join(' '), {
    'product title': productTitle,
    ...createProductPricingProperties(
      productPricesAndCurrencies,
      productPrice,
      productCurrencyCode,
    ),
    'artist name': artistName,
  });
};

export const TrackRevealed = ({
  page,
  context,
  title,
  duration,
}: RevealedOptions) => {
  return track(`${page} page information revealed`, {
    'title': title,
    'duration': duration,
    'location': context,
  });
};

export const TrackFramingSwitched = ({
  page,
  context,
  frameSelected,
  frameName,
  framePrice,
  product,
}: FramingOptions) => {
  return track(`${page} page ${context} switched`, {
    ...transformProductToEventObject(product),
    'frame name': frameName,
    'frame price': framePrice,
    'frame selected': frameSelected,
  });
};

export const TrackExperimentViewed = ({
  experimentId,
  variationId,
}: ExperimentOptions) => {
  return track('Experiment Viewed', {
    experimentId,
    variationId,
  });
};

export const TrackNftAuctionLeaderBoardClick = ({
  artist,
  product,
  context,
  operation,
}: NftAuctionLeaderBoardClickOptions) => {
  return track(`leaderboard clicked`, {
    'artist name': artist.name,
    'link': operation,
    'product title': product.title,
    ...createProductPriceProperty(product.price, product.digitalCurrency),
    'product medium': ['nft'],
    'location': context,
  });
};

export const TrackVariantGallery = ({
  action,
  position,
  title,
  artistName,
  productTitle,
  productCurrencyCode,
  productPrice,
  productPricesAndCurrencies,
  productMedium = ['nft'],
}: TrackVariantGalleryOptions) => {
  return track(`variant gallery clicked`, {
    'artist name': artistName,
    'product title': productTitle,
    'product medium': productMedium,
    ...createProductPricingProperties(
      productPricesAndCurrencies,
      productPrice,
      productCurrencyCode,
    ),
    'title': title,
    'action': action,
    'position': position,
  });
};

export const TrackNftClaim = ({
  object,
  location,
  state,
  message,
  linkTitle,
  artistName,
  productTitle,
  productCurrencyCode,
  productPrice,
  productPricesAndCurrencies,
}: TrackNftClaimOptions) => {
  return track('claim nft clicked', {
    'artist name': artistName,
    'product title': productTitle,
    'product medium': ['nft'],
    ...createProductPricingProperties(
      productPricesAndCurrencies,
      productPrice,
      productCurrencyCode,
    ),
    'object': object,
    'location': location,
    'state': state,
    'message': message,
    'link title': linkTitle,
  });
};

export const TrackMessageDisplayed = ({
  message,
  location,
  artistName,
  productTitle,
  productCurrencyCode,
  productPrice,
  productPricesAndCurrencies,
  productMedium,
  linkTitle,
  type = 'info',
}: TrackMessageDisplayedOptions) => {
  return track(`${type} message viewed`, {
    'message': message,
    'artist name': artistName,
    'product title': productTitle,
    'product medium': productMedium,
    ...createProductPricingProperties(
      productPricesAndCurrencies,
      productPrice,
      productCurrencyCode,
    ),
    'location': location,
    'link title': linkTitle,
  });
};

export const TrackMessageClicked = ({
  message,
  location,
  artistName,
  productTitle,
  productCurrencyCode,
  productPrice,
  productPricesAndCurrencies,
  productMedium,
  linkTitle,
  type = 'info',
}: TrackMessageDisplayedOptions) => {
  return track(`${type} message clicked`, {
    'message': message,
    'artist name': artistName,
    'product title': productTitle,
    'product medium': productMedium,
    ...createProductPricingProperties(
      productPricesAndCurrencies,
      productPrice,
      productCurrencyCode,
    ),
    'location': location,
    'link title': linkTitle,
  });
};

export const TrackNftAuctionPlaceBid = ({
  event,
  object,
  location,
  state,
  message,
  linkTitle,
  artistName,
  productTitle,
  productCurrencyCode,
  productPrice,
  productPricesAndCurrencies,
}: TrackNftAuctionPlaceBidOptions) => {
  return track(event, {
    'artist name': artistName,
    'product title': productTitle,
    'product medium': ['nft'],
    ...createProductPricingProperties(
      productPricesAndCurrencies,
      productPrice,
      productCurrencyCode,
    ),
    'object': object,
    'location': location,
    'state': state,
    'message': message,
    'link title': linkTitle,
  });
};

export const TrackAllFiltersCleared = ({
  page,
  location,
}: AllFiltersClearedOptions) => {
  return track(`${page} page all filters cleared`, {
    'link location': location,
  });
};

export const TrackFilterCleared = ({
  page,
  filterLabel,
}: FilterClearedOptions) => {
  return track(`${page} page filter cleared`, {
    'filter label': filterLabel,
  });
};

export const TrackFiltersClicked = ({
  page,
  indexSearchQuery,
  indexOrderFilter,
  indexMediumFilter,
  indexAvailabilityFilter,
  indexFinishingFilter,
  indexReleaseFilter,
  indexLength,
}: FiltersClickedOptions) => {
  return track(`${page} page filters clicked`, {
    'index search query': indexSearchQuery,
    'index order filter': indexOrderFilter,
    'index medium filter': indexMediumFilter,
    'index availability filter': indexAvailabilityFilter,
    'index finishing filter': indexFinishingFilter,
    'index release filter': indexReleaseFilter,
    'index length': indexLength,
  });
};

export const TrackFiltersSelected = ({
  page,
  indexSearchQuery,
  indexOrderFilter,
  indexMediumFilter,
  indexAvailabilityFilter,
  indexFinishingFilter,
  indexReleaseFilter,
  indexLength,
}: FiltersSelectedOptions) => {
  return track(`${page} page filters selected`, {
    'index search query': indexSearchQuery,
    'index order filter': indexOrderFilter,
    'index medium filter': indexMediumFilter,
    'index availability filter': indexAvailabilityFilter,
    'index finishing filter': indexFinishingFilter,
    'index release filter': indexReleaseFilter,
    'index length': indexLength,
  });
};

export const TrackAccordion = ({
  page,
  context,
  expanded,
  title,
  type,
  isFAQ,
  product,
}: AccordionOptions) => {
  return track(`${page} ${context} ${expanded ? 'expanded' : 'collapsed'}`, {
    [isFAQ ? 'faq title' : 'title']: title,
    type: type,
    ...(product && transformProductToEventObject(product)),
  });
};

export const TrackRelatedDigitalProducts = ({
  page,
  product,
}: RelatedDigitalProducts) => {
  return track(`${page} paired NFT clicked`, {
    ...(product && transformProductToEventObject(product)),
  });
};

export const TrackNftPrintServiceForm = ({
  walletAddress,
  productTitle,
  action,
  frameId = '',
  tokenId = '',
}: TrackNftPrintServiceFormOptions): void => {
  track('nft print service', {
    'action': action,
    'product title': productTitle,
    'wallet address': walletAddress,
    'frame id': frameId,
    'token id': tokenId,
  });
};

export function getStandardProductProperties(
  product: ProductType | ProductV2Storyblok,
  purchasingController: ProductPurchasingController<ProductPurchaseDetails>,
  drawStage?: DrawStage,
  isPreOrderAvailable?: boolean,
): ProductAnalytics {
  return {
    type: 'Product',
    id: product.id[0].id,
    title: product.title,
    artistName: (product.productArtist?.[0] as ResolvedRelatedArtist)?.artist
      ?.name,
    releaseType: storyblokProductReleaseType(product as unknown as ProductType),
    editionSize: product.editionSize ? parseInt(product.editionSize) : 0,
    price: purchasingController?.product?.price.toString(),
    pricesAndCurrencies: product.pricesAndAvailability?.pricesAndCurrencies,
    releaseDate: product.launchDate,
    status: getProductStatus(product, purchasingController),
    medium: product.medium ? product.medium[0] : undefined,

    drawStage,
    isPreOrderAvailable,
  };
}

function createProductPricingProperties(
  prices?: PriceAndCurrency[] | undefined,
  price?: string | number | undefined,
  currencyCode?: CurrencyCode | DigitalCurrencyType | undefined,
): Record<string, unknown> {
  return {
    ...createProductPriceProperty(price, currencyCode),
    ...createProductPriceProperties(prices),
  };
}

export function createProductPriceProperties(
  prices?: PriceAndCurrency[],
): Record<string, string> {
  return (prices ?? []).reduce(
    (acc, val) => ({
      ...acc,
      [`product price ${val.currency.toLocaleLowerCase()}`]: val.price,
    }),
    {},
  );
}

function createProductPriceProperty(
  price: string | number | undefined,
  currencyCode: CurrencyCode | DigitalCurrencyType | undefined,
): Record<string, unknown> {
  if (!price && !currencyCode) return {};

  if (!currencyCode) return { 'product price': price };

  return { [`product price ${currencyCode.toLocaleLowerCase()}`]: price };
}

function tryFormatShopifyId(id: string | undefined) {
  if (id === undefined) return undefined;

  try {
    return shopifyGraphqlIdToAdminId(id);
  } catch (err) {
    return id;
  }
}
