import { storyblokProductReleaseType } from '../utils/storyblokReleaseType';
import type {
  ProductPurchaseDetails,
  ProductPurchasingController,
  PurchasingState,
} from '../types/Purchasing';
import { isLaunchDateInPast } from '../components/Containers/PurchaseForm/utils';
import { ProductV2Storyblok } from '../types/generated-storyblok';

export function purchasingState({
  releaseType,
  closingDate,
  unlocked,
  available,
  tags,
}: {
  releaseType: string | StoryblokReleaseType;
  closingDate?: string;
  unlocked?: boolean;
  available?: boolean;
  tags?: string[];
}): PurchasingState {
  const release = storyblokProductReleaseType({ releaseType });

  // If the release is a draw, and the draw has closed, mark as sold out
  if (release === 'draw' && closingDate && isLaunchDateInPast(closingDate))
    return 'SOLD_OUT';

  if (!tags) return 'UNKNOWN';

  // If the Shopify "Sold out" tag is present, mark as sold out
  if (tags?.includes('Sold out')) return 'SOLD_OUT';

  if (tags?.includes('Coming soon') && !(unlocked && available)) {
    return 'COMING_SOON';
  }

  if (release === 'private') return 'ENQUIRY';

  if (!available) return 'UNAVAILABLE';

  return 'AVAILABLE';
}

export const getProductStatus = (
  product: ProductType | ProductV2Storyblok,
  purchasingController: ProductPurchasingController<any> | undefined,
) => {
  if (!purchasingController?.product) return 'unavailable';

  const state = purchasingState({
    releaseType: product.releaseType,
    closingDate: product.closingDate,
    unlocked: purchasingController.unlocked,
    available: purchasingController?.product?.available,
    tags: purchasingController?.product?.tags,
  });

  if (state === 'COMING_SOON') return 'coming soon';
  if (state === 'ENQUIRY') return 'enquiry';
  if (state === 'SOLD_OUT') return 'sold out';

  return 'available';
};

export const getProductTileStatus = (product: Product) => {
  if (product.availability === 'Coming soon') return 'coming soon';
  if (product.releaseType === 'private') return 'enquiry';
  if (product.availability === 'Sold out') return 'sold out';
  return 'available';
};

export const getProductRef = (
  purchasingController: ProductPurchasingController<ProductPurchaseDetails>,
): string => purchasingController?.product?.sku?.substring(0, 14) ?? '';
