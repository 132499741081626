import dateformat from 'dateformat';
import { add, differenceInHours, format } from 'date-fns';
import { WEEKDAYS } from '../constants/product';
import { tryEach } from './errors';

interface FormatDateOptions {
  includeYear?: boolean;
  includeWeekday?: boolean;
  shortenMonth?: boolean;
}

export const parseDate = (date: string): Date | null => {
  return tryEach(
    () => new Date(date.replace(' ', 'T') + ':00.000Z'),
    () => null,
  );
};

export const formatDate = (
  date: string,
  options: FormatDateOptions = {
    includeYear: true,
    includeWeekday: false,
    shortenMonth: false,
  },
): string | null => {
  const format = (() => {
    if (!options.includeYear && !options.shortenMonth) return 'dd mmmm';
    if (!options.includeYear && options.shortenMonth) return 'dd mmm';

    if (options.shortenMonth) {
      return 'mmm dd, yyyy';
    }
    return 'mmmm dd, yyyy';
  })();

  let formattedDate = tryEach(
    () => dateformat(date.replace(' ', 'T'), format),
    () => dateformat(Date.parse(date.replace(' ', 'T')), format),
    () => null,
  );

  if (options.includeWeekday) {
    const datef = new Date(date);
    const day = datef.getDay();

    formattedDate = `${WEEKDAYS[day]}, ${formattedDate}`;
  }

  return formattedDate;
};

export const hoursBetween = (
  start: string | undefined,
  end: string | undefined,
): number | null => {
  if (start === undefined || end === undefined) return null;

  const startDate = parseDate(start);
  const endDate = parseDate(end);

  if (startDate === null || endDate === null) return null;

  return differenceInHours(endDate, startDate);
};

export const formatTimedReleaseDate = (
  start: string,
  end: string | undefined,
): string | null => {
  if (!start || !end) return null;

  const startDate = parseDate(start);
  const endDate = parseDate(end);

  if (!startDate || !endDate) return null;

  const hours = differenceInHours(endDate, startDate);

  if (hours <= 72) return `${hours} hour`;

  const days = Math.round(hours / 24);

  return `${days} days`;
};

export const formatDateRange = (date: Date, fromOffset = 0, toOffset = 0) => {
  const from = add(date, { days: fromOffset });
  const fromMonth = format(from, 'MMMM');
  const fromDay = format(from, 'd');

  const to = add(from, { days: toOffset });
  const toMonth = format(to, 'MMMM');
  const toDay = format(to, 'd');

  return fromMonth === toMonth
    ? `${fromDay}-${toDay} ${fromMonth}`
    : `${fromDay} ${fromMonth}-${toDay} ${toMonth}`;
};
