import { useEffect, useState } from 'react';
import StoryblokClient, {
  StoryblokResult,
  StoryData,
} from 'storyblok-js-client';
import { HomeV2PageType } from '../types/Home';

const Storyblok = new StoryblokClient({
  accessToken: process.env.STORYBLOK_TOKEN,
  cache: {
    clear: 'auto',
    type: 'memory',
  },
});

export const isStoryblokDraftMode = () =>
  process.env.NEXT_PUBLIC_STORYBLOK_PAGE_VERSION === 'draft';

export type StoriesResponse<T> = {
  data: {
    stories: T[];
  };
  perPage: number;
  total: number;
} | null;

export type StoryResponse<T> = { data: { story: T } } | null;

export interface StoryBlokResponse extends StoryblokResult {
  data: {
    story: StoryData<HomeV2PageType>;
  };
}

export type GetStoryblokPageOptions = Partial<{
  by_slugs?: string;
  by_uuids?: string;
  excluding_fields?: string;
  excluding_slugs?: string;
  preview?: boolean;
  locale?: string;
  resolve_relations?: string[];
  resolve_links?: string;
  filter_query?: any;
  search_term?: string;
  starts_with?: string;
  is_startpage?: boolean;
  per_page?: number;
  page?: number;
  sort_by?: string;
}>;

export async function getStoryblokPage(
  path: string,
  options: GetStoryblokPageOptions = {},
): Promise<StoryblokResult | null> {
  const fetchDraftContent = options.preview || isStoryblokDraftMode();
  const version = fetchDraftContent ? 'draft' : 'published';
  const cv = fetchDraftContent ? Date.now() : 0;
  try {
    return await Storyblok.get(path, {
      version,
      cv,
      by_slugs: options.by_slugs,
      by_uuids: options.by_uuids,
      excluding_fields: options.excluding_fields,
      excluding_slugs: options.excluding_slugs,
      language: options.locale,
      search_term: options.search_term,
      starts_with: options.starts_with,
      resolve_links: options.resolve_links,
      resolve_relations: options.resolve_relations,
      is_startpage: options.is_startpage,
      filter_query: options.filter_query,
      per_page: options.per_page,
      page: options.page,
      sort_by: options.sort_by,
    });
  } catch (e) {
    if (e.response && e.response.status === 404) return null;

    throw e;
  }
}

export async function getStoryblokPaginated<T>(
  options: GetStoryblokPageOptions = {},
) {
  const page = options.page || 1;
  const perPage = options.per_page || 100;

  let total = page;
  let stories: T[] = [];

  for (let i = page; i <= total; i++) {
    const res: StoriesResponse<T> = await getStoryblokPage('cdn/stories', {
      ...options,
      page: i,
      per_page: perPage,
    });
    if (res) {
      total = Math.ceil(res.total / perPage);
      stories = stories.concat(res.data.stories || []);
    }
  }
  return stories;
}

export async function getStoryblokFaq(
  name: string,
  uid: string,
  options: GetStoryblokPageOptions = {},
): Promise<StoryblokTextAreaType> {
  const page = await getStoryblokPage(`cdn/stories/support/${name}`, options);
  const content = page?.data?.story?.content?.content || [];

  return content.find((item: any) => item._uid === uid)?.text;
}

declare global {
  interface Window {
    StoryblokBridge: {
      new (n: {
        resolveRelations: Array<string>;
        language: string;
      }): StoryblokBridge;
    };
  }
}

export function useStoryblok<T extends StoryData>(
  originalStory: T,
  preview: boolean,
  locale: string,
): T {
  if (!preview) {
    return originalStory;
  }

  const [story, setStory] = useState(originalStory);

  // adds the events for updating the visual editor
  // see https://www.storyblok.com/docs/guide/essentials/visual-editor#initializing-the-storyblok-js-bridge
  function initEventListeners() {
    const { StoryblokBridge } = window;
    if (typeof StoryblokBridge !== 'undefined') {
      // initialize the bridge with your token
      const storyblokInstance = new StoryblokBridge({
        resolveRelations: [
          'relatedArtist.artist',
          'relatedSeries.series',
          'seriesProducts.products',
          'variantList.variants',
        ],
        language: locale,
      });

      // reload on Next.js page on save or publish event in the Visual Editor
      storyblokInstance.on(['change', 'published'], () => location.reload());

      // live update the story on input events
      storyblokInstance.on('input', (event: StoryblokEventPayload) => {
        if (story && event.story.content._uid === story.content._uid) {
          setStory(event.story);
        }
      });

      storyblokInstance.on('enterEditmode', (event: StoryblokEventPayload) => {
        // loading the draft version on initial enter of editor
        Storyblok.get(`cdn/stories/${event.storyId}`, {
          version: 'draft',
          resolve_relations: [],
          language: locale,
        })
          .then(({ data }) => {
            if (data.story) {
              setStory(data.story);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      });
    }
  }

  // appends the bridge script tag to our document
  // see https://www.storyblok.com/docs/guide/essentials/visual-editor#installing-the-storyblok-js-bridge
  function addBridge(callback: any) {
    // check if the script is already present
    const existingScript = document.getElementById('storyblokBridge');
    if (!existingScript) {
      const script = document.createElement('script');
      script.src = '//app.storyblok.com/f/storyblok-v2-latest.js';
      script.id = 'storyblokBridge';
      document.body.appendChild(script);
      script.onload = () => {
        // once the script is loaded, init the event listeners
        callback();
      };
    } else {
      callback();
    }
  }

  useEffect(() => {
    setStory(originalStory);
  }, [originalStory]);

  useEffect(() => {
    // first load the bridge, then initialize the event listeners
    addBridge(initEventListeners);
  }, []);

  return story;
}

export const getStoryblokContentBySlug = async (
  rootPath: string,
  slug: string,
  locale: string | undefined,
  preview = false,
  extraResolvedRelations: string[] = [],
): Promise<StoryblokResult | null> => {
  const storyblokPath = `cdn/stories/${rootPath}/${slug}`;
  return await getStoryblokPage(storyblokPath, {
    locale,
    preview,
    resolve_links: 'story',
    resolve_relations: [
      'gatedRelease.gatedRelease',
      'relatedArtist.artist',
      'relatedSeries.series',
      'seriesProducts.products',
      'variantList.variants',
      'fromTheArtistContainer.event',
      ...extraResolvedRelations,
    ],
  });
};

export default Storyblok;
